import React from "react"
import "../components/CardTeam"
import CardTeam from "../components/CardTeam"
import Layout from "../components/layout"
import Seo from "../components/seo"

const OurteamPage = () => (
  <Layout>
    <Seo title="Our team" />
    <div className="HeroPage">
      <h1>Nosotros</h1>
      <p>
        Contamos con profesionales capacitados que garantizan un trabajo de
        calidad.
      </p>
    </div>
    <div className="SectionOurTeam">
      <CardTeam
        teamImage={require("../images/eskimo.png")}
        role="Esp. en Contrales Hidroeléctricas, Plantas de Bombeo, Presas y Sifones"
        name="Ronald Vergara Rojas"
        title="Ing. Mecánico"
      />
      <CardTeam
        teamImage={require("../images/eskimo.png")}
        role="Esp. en Estudio de Impacto Ambiental y Tecnología de Alimentos"
        name="Luz Pareja Sota"
        title="Quimico"
      />
      <CardTeam
        teamImage={require("../images/eskimo.png")}
        role="Especialista en Estructuras"
        name="Julio Rojas Bravo"
        title="Ing. Civil"
      />
      <CardTeam
        teamImage={require("../images/eskimo.png")}
        role="Esp. en Obras Hidráulicas y Medio Ambiente"
        name="Wilbert Trujillano Arriola"
        title="Ing. Civil"
      />
      <CardTeam
        teamImage={require("../images/eskimo.png")}
        role="Esp. en Estudios de Impacto Ambiente"
        name="Maria Isabel Cazorla"
        title="Bióloga"
      />
      <CardTeam
        teamImage={require("../images/eskimo.png")}
        role="Esp. en Líneas de Media tensión y Subestaciones"
        name="Nilton Hinojosa Valencia"
        title="Ing. Electricista"
      />
      <CardTeam
        teamImage={require("../images/eskimo.png")}
        role="Esp. en Desarrollo de Aplicaciones y Machine Learning"
        name="Ronald Vergara Pareja"
        title="Ing. de Sistemas"
      />
    </div>
  </Layout>
)

export default OurteamPage
