import React from "react"
import styled from "styled-components"

const CardTeamItem = styled.div`
  display: grid;
  grid-template-rows: 3em 40px 70px;
  margin: 0 40px;
  grid-gap: 0;

  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(197, 197, 197, 0.25);
`

// const CardTeamImage = styled.div`
//   width: 300px;
//   height: 344px;
//   position: relative;
//   overflow: hidden;
//   border-radius: 20px;
//   box-shadow: 0 20px 40px rgba(131, 131, 131, 0.25);
//   display: grid;
// `

// const TeamImage = styled.img`
//   position: relative;
//   top: 0;
//   height: 200px;
//   align-self: center;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
// `

const TeamRole = styled.p`
  margin: 0 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #9da6b8;
  text-align: center;
  align-self: start;
`

const TeamName = styled.h4`
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  color: #373c46;
  text-align: center;
  width: 100%;
  align-self: end;
  margin: 0 auto;
`

const TeamTitle = styled.p`
  margin: 5px 20px 0 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #9da6b8;
  text-align: center;
`

const CardTeam = props => (
  <CardTeamItem>
    {/* <CardTeamImage>
            <TeamImage src={props.teamImage} />
        </CardTeamImage> */}
    <TeamName>{props.name}</TeamName>
    <TeamTitle>{props.title}</TeamTitle>
    <TeamRole>{props.role}</TeamRole>
  </CardTeamItem>
)

export default CardTeam
